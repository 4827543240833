.thumbnail-list {
  background-color: #121212;
  font-size: calc(10px + 2vmin);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thumbnail {
  width: 90vmin;
  height: auto;
  margin: 1.5rem;
  background: black;
  transition: all 0.5s linear;
  border: 1vmin solid #eee;
}

a.darken {
  position: relative;
  cursor: pointer;
}

a.darken:hover img {
  opacity: 0.2;
}

a.darken span {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  opacity: 0;
  color: #eee;
}

a.darken:hover span {
  opacity: 1;
  transition: all 0.5s linear;
}
