.references {
  display: flex;
  flex-direction: column;
  width: 96vmin;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 0;
  list-style-type: none;
}

.references li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #222;
  width: 99%;
  height: 12vmin;
  border: 0.5vmin solid #eee;
  margin-bottom: 0.5rem;
}

.reference-link a {
  text-decoration: none;
  color: #bb86fc;
}

.reference-link a:hover {
  text-decoration: underline;
}

@media only screen and (max-device-width: 480px) {
  .references {
    display: flex;
    flex-direction: column;
    width: 96vmin;
    align-items: center;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .references li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style-type: none;
    background-color: #222;
    width: 90%;
    height: 26vmin;
    border: 0.5vmin solid #eee;
    margin-bottom: 0.5rem;
  }

  .reference-link a {
    text-decoration: none;
    color: #bb86fc;
    font-size: calc(8px + 2vmin);
  }

  .reference-data {
    font-size: calc(8px + 2vmin);
  }
}

.hidden {
  visibility: hidden;
}
