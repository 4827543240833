.header {
  background-color: #121212;
}

.logo {
  width: 100%;
}

.tv {
  display: inline-block;
  margin-left: 4rem;
}

.logo a {
  cursor: pointer;
}

.logo-letter {
  height: 20vmin;
  margin-top: 1.5rem;
  opacity: 0.9;
}

.social-icon {
  margin: 1rem;
  transition: transform 0.2s;
}

.social-icon:hover {
  transform: scale(1.5);
}

.custom-icon {
  display: inline-block;
  width: 70px;
  height: 70px;
  vertical-align: middle;
  margin: 1rem;
  transition: transform 0.2s;
}

.custom-icon:hover {
  transform: scale(1.5);
}

@media only screen and (max-device-width: 1024px) {
  .tv {
    display: block;
    margin: 0;
  }
  .tv .logo-letter {
    height: 20vmin;
    margin-top: 0;
    opacity: 0.9;
  }
}

@media only screen and (max-device-width: 480px) {
  .custom-icon {
    display: inline-block;
    width: 45px;
    height: 45px;
    vertical-align: middle;
    margin: 1rem;
    transition: transform 0.2s;
  }
}
