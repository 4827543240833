.credits {
  columns: 2;
  width: 96vmin;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 0;
  list-style-type: none;
}

.credits-single {
  display: flex;
  flex-direction: column;
  width: 96vmin;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 0;
  list-style-type: none;
}

ol li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #222;
  width: 98%;
  height: 12vmin;
  border: 0.5vmin solid #eee;
  margin-bottom: 0.5rem;
}

ol .list-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  background-color: #121212;
  width: 98%;
  height: 8vmin;
  border: none;
  text-decoration: underline;
}

@media only screen and (max-device-width: 480px) {
  .credits {
    display: flex;
    flex-direction: column;
    width: 96vmin;
    align-items: center;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  ol li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style-type: none;
    background-color: #222;
    width: 90%;
    height: 22vmin;
    border: 0.5vmin solid #eee;
    margin-bottom: 0.5rem;
  }
}

.credit-link a {
  text-decoration: none;
  color: #bb86fc;
}

.credit-link a:hover {
  text-decoration: underline;
}

.hidden {
  visibility: hidden;
}
