.page-content {
  background-color: #121212;
  font-size: calc(2px + 2vmin);
  color: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video {
  border: 1vmin solid #eee;
  margin-top: 1.5rem;
}

.video-description {
  width: 96vmin;
  text-align: left;
  line-height: 2;
}

@media only screen and (max-device-width: 480px) {
  .page-content {
    background-color: #121212;
    font-size: calc(8px + 2vmin);
    color: #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .video-description {
    width: 90%;
    text-align: left;
    line-height: 2;
  }
}
